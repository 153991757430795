<template>
  <v-card>
    <v-card-title>
      List Meta Data
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="datas" :search="search" mobile-breakpoint="0">
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.name }}</td>
          <td>{{ format_date(row.item.created_at) }}</td>
          <td>{{ format_date(row.item.updated_at) }}</td>
          <td>
            <v-btn small depressed color="warning" @click="$router.push(`/meta/${row.item.metaKey}`)"> Edit </v-btn>
          </td>
        </tr>
      </template></v-data-table
    >
  </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "Meta",

  components: {},

  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Dibuat", value: "created_at" },
        { text: "Diupdate", value: "updated_at" },
        { text: "Atur", value: "" },
      ],
      datas: [],
    };
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
  },

  mounted() {
    const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

    axios
      .create({ withCredentials: false })
      .get(`${process.env.VUE_APP_API_URL}/v1/admin/all_meta_data`, {
        headers: { Authorization: AuthStr },
      })
      .then((response) => {
        this.loading = false;

        this.datas = response.data.result;

        // console.log(response);
      })
      .catch((error) => {
        this.$toast.warning(error.response.data.message || "Unauthorized");
        this.loading = false;
        console.log(error);
      });
  },
};
</script>
